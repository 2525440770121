<template>
	<!--记录生活页面-->
	<div >
    <div style="height:90px;"></div>
		<div v-for="(item,index) in daily_list" :key="index" >
		  <div v-if="index%2==0" style="display:flex;justify-content:center;margin-bottom:20px;">
		   <img src="@/assets/img/touxiang.jpg" style="height:70px;width:70px;border-radius:50%;margin-top:10px;">
		   <div class="tranagleWhite" style="margin-top:20px;transform:rotate(270deg);"></div>
    	   <div style="background-color:#FFFFFF;border-radius:16px;padding:20px 40px 15px;opacity:0.88;">
			   <div style="width:600px;font-size:16px;">
				   {{item.content}}
				</div>
				<!--图片集合-->
				<div style="display:flex;margin-top:10px;display:flex;flex-wrap:wrap;align-content:flex-start;width:600px;">
					<div  v-for="(item2,index) in item.img_list" :key="index">
						<img  :src="item2" style="height:80px;width:80px;margin-right:10px;cursor:pointer;" @click="enlargeImg(item.img_list,index)">
					</div>
				</div>
				<div style="margin-top:10px;margin-bottom:10px;height:1px;background-color:#DFE0E5;"></div>
				<div style="font-size:14px;">
					{{item.create_time | timeFilter}}
				</div>
		   </div>
		  </div>
		  <div v-else style="display:flex;justify-content:center;margin-bottom:20px;">
			<div style="background-color:#FFFFFF;border-radius:16px;padding:20px 40px 15px;opacity:0.88;">
			   <div style="width:600px;font-size:16px;">
				   {{item.content}}
				</div>
				<!--图片集合-->
				<div style="display:flex;margin-top:10px;display:flex;flex-wrap:wrap;align-content:flex-start;width:600px;">
					<div v-for="(item2,index) in item.img_list" :key="index">
						<img v-if="item2!=''" :src="item2" style="height:80px;width:80px;margin-right:10px;cursor:pointer;" @click="enlargeImg(item.img_list,index)">
					</div>
				</div>

				<div style="margin-top:10px;margin-bottom:10px;height:1px;background-color:#DFE0E5;"></div>
				<div style="font-size:14px;">
					{{item.create_time | timeFilter}}
				</div>
		   </div>
		   <div class="tranagleWhite" style="margin-top:20px;transform:rotate(90deg);"></div>
		   <img src="~@/assets/img/touxiang.jpg" style="height:70px;width:70px;border-radius:50%;margin-top:10px;">  
		  </div>
		</div>
		<div style="height:100px;"></div>
		
		<!--查看大图弹窗-->
		<div v-if="enlarge_img==true" @click="enlarge_img=false" >
			<div style="position: fixed;bottom: 0;left: 0;width: 100%;height: 100%;opacity:0.8;background-color: #000000;z-index:20;" ></div>
			<div style="position: fixed;bottom: 0;left: 0;width: 100%;height: 100%;;z-index:21;display:flex;align-items:center;justify-content:center;">
				<img src="~@/assets/icon/houtui.png" style="transform:rotate(180deg);height:120px;position:fixed;top:50%-60px;left:16vw;cursor: pointer;"  @click.stop="changeLeft()">
				<img :src="select_img" style="max-height:660px;max-width:900px;"  @click.stop >
				<img src="~@/assets/icon/houtui.png" style="height:120px;position:fixed;top:50%-60px;right:16vw;cursor: pointer;" @click.stop="changeRight()">
			</div>
		</div>
		
	</div>
</template>
<script>
export default {
	data() {
		return {
			enlarge_img:false,//放大图片弹窗默认关闭
			daily_list:[{},{}],
			select_img:'',//用户选中的图片
			item_img:'',//用户选择的图片列表
			index:'',//用户选择的图片索引
		};
	},
	created(){
		this.getDailyList()
	},
	methods:{
		//查看大图方法,接收两个参数,1图片列表,2当前点击图片
		enlargeImg(item_img,index){
			this.item_img = item_img;
			this.index = index;
			this.select_img = item_img[index];
			// console.log(item_img,index)
			this.enlarge_img = true;
		},
		//向左查看图片
		changeLeft(){
			//如果是第一张
			if(this.index==0){
				this.$message.warning('这已经是第一张了')
			}else{
				this.index = this.index*1-1
				this.select_img = this.item_img[this.index];
			}
		},
		//向右查看图片
		changeRight(){
			//如果是最后一张
			if(this.index==this.item_img.length-1){
				this.$message.warning('没有更多图片啦！')
			}else{
				this.index = this.index*1+1
				this.select_img = this.item_img[this.index];
			}
		},
		//获取说说列表接口
		getDailyList(){
			this.$axios.get(this.$url.getDaily).then(res=>{
				this.daily_list = res.data.data;
				console.log(res);
			})
		}
	}
};
</script>
<style  scoped>
.tranagleWhite{
	width:0px;
    height:0px;
    border:20px solid;    /*更改border的宽度即可改变三角的大小*/
    border-color:transparent transparent white transparent;  
                /*更改color可以改变三角的方向*/
	opacity:0.88;
}
</style>
